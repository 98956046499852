import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

const Image = props => {
  return (
    <img
      src={imageUrlFor(buildImageObj(props.src))
        .width(props.width)
        .auto("format")
        .url()}
      className={props.className}
      alt={props.alt}
      style={{ width: props.width / 2, ...props.style }}
    />
  );
};

export default Image;
