import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FaEnvelopeOpenText, FaTwitter } from "react-icons/fa";
import Hero from "./Hero";
import Image from "./Image";
import PortableText from "./portableText";

const Post = props => {
  const { author } = props.authors[0];

  console.dir(props);

  return (
    <>
      <Hero
        image={props.mainImage}
        heading={props.title}
        subheading={props.subtitle}
        animated={true}
        className="mb-3"
        height="67vh"
      />
      <Container>
        <Row>
          <Col lg={8}>
            <div style={{ fontSize: "1.5rem", lineHeight: 1.4 }}>
              <PortableText blocks={props._rawExcerpt} />
            </div>
            <hr />
            <PortableText blocks={props._rawBody} />
          </Col>
          <Col lg={4}>
            <div className="position-sticky" style={{ top: "1rem" }}>
              <Card className="mb-3">
                <Card.Body>
                  <div className="d-flex align-items-center mb-2">
                    <Image
                      src={author._rawImage}
                      className="mr-1"
                      style={{ borderRadius: "50px" }}
                      width={60}
                    />
                    <div>
                      {author.name} · {props.publishedAt}
                    </div>
                  </div>
                  <Button
                    href={`https://twitter.com/intent/tweet?text=Read ${props.title} on Rebase https://rebase.foundation${props.path} cc @rebasefoundtn`}
                    className="text-decoration-none"
                    variant="dark"
                    size="sm"
                  >
                    <FaTwitter /> Tweet this post
                  </Button>
                </Card.Body>
              </Card>
              <Card className="mb-3">
                <Card.Body>
                  <div className="overline">Latest Spotlight</div>
                </Card.Body>
                <Link to="/network/thorchain">
                  <img src={require("../images/thorchain-post.jpg")} className="img-fluid" />
                </Link>
                <Card.Body>
                  <h5 className="mb-1">THORChain</h5>
                  <div className="mb-2 text-muted">
                    Get to grips with the most innovative decentralized liquidity protocol for the
                    cross-chain world. Interactive explainers, videos and jargonless writing.
                  </div>
                  <Link to="/network/thorchain">Learn more →</Link>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <div className="overline mb-2">Newsletter</div>
                  <h5 className="mb-2">Tools for the discerning crypto investor</h5>
                  <Button
                    className="text-decoration-none"
                    href="https://foundation.us4.list-manage.com/subscribe?u=caae9342f52a16ea36727f89c&id=413d37b977"
                    size="sm"
                  >
                    <FaEnvelopeOpenText /> Subscribe
                  </Button>
                  <br />
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Post;
